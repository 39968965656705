import { Injectable } from '@angular/core';
import {
  FiResourceService,
  FiUrlService,
  Resource,
} from '@fi-sas/webpage/libs/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { catchError, switchMap, first } from 'rxjs/operators';
import { FaqModel, FAQConfig } from '../models/chatbot.model';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  isBotStarted = new BehaviorSubject<boolean>(false);
  isChatBoxVisible = new BehaviorSubject<boolean>(false);
  selectedServiceID = new BehaviorSubject<number | null>(null);

  constructor(
    private resourceService: FiResourceService,
    private urlService: FiUrlService,
    private translate: TranslateService,
    private http: HttpClient
  ) {}

  toggleChatBoxVisibility(): void {
    const currentVisibility = this.isChatBoxVisible.getValue();
    this.isChatBoxVisible.next(!currentVisibility);
  }

  resetChatBotState(): void {
    this.isChatBoxVisible.next(false);
    this.isBotStarted.next(false);
    this.selectedServiceID.next(null);
  }

  getSelectedServiceID(): Observable<number | null> {
    return this.selectedServiceID.asObservable();
  }

  setSelectedServiceID(serviceID: number | null): void {
    this.selectedServiceID.next(serviceID);
  }

  getChatbotConfig(): Observable<FAQConfig> {
    const params = new HttpParams().append('key', 'CHATBOT_STATUS');
    return this.resourceService
      .list<FAQConfig>(this.urlService.get('FAQS.STATUS'), {
        params,
        headers: {
          'no-error': 'true',
        },
      })
      .pipe(
        first(),
        map((response: any) => {
          const config = new FAQConfig();
          config.CHATBOT_STATUS = response.data?.[0]?.CHATBOT_STATUS ?? false;
          return config;
        })
      );
  }

  getChatbotFaqs(): Observable<Resource<FaqModel>> {
    const params = new HttpParams().append('withRelated', 'service,answers');
    return this.resourceService
      .list<FaqModel>(this.urlService.get('FAQS.FAQ'), {
        params,
        headers: {
          'no-error': 'true',
        },
      })
      .pipe(
        first(),
        map((response: Resource<FaqModel>) => {
          response.data = response.data.filter(
            (faq: FaqModel) => faq.status === 'ACTIVE'
          );
          return response;
        })
      );
  }

  // Compare the user's question with the FAQs in the database using the Anthropic API.
  findBestMatchingFAQ(
    userQuestion: string,
    currentLangId: number,
    currentServiceID: number
  ): Observable<string | { error: string }> {
    return this.generateContent(
      userQuestion,
      currentLangId,
      currentServiceID
    ).pipe(
      first(),
      switchMap((response) => {
        if (
          typeof response === 'string' &&
          response.trim() === 'No Answer Found'
        ) {
          return of({
            error: this.translate.instant('CHATBOT.ERRORS.NO_ANSWER'),
          });
        }
        return of(
          response || {
            error: this.translate.instant('CHATBOT.ERRORS.NO_ANSWER'),
          }
        );
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  // Call the backend to generate content using the Anthropic API.
  private generateContent(
    userQuestion,
    language_id,
    service_id
  ): Observable<string> {
    const url = this.urlService.get('FAQS.BOT');
    return this.http
      .post<any>(url, { userQuestion, language_id, service_id })
      .pipe(
        first(),
        switchMap((response) => {
          if (
            response.status === 'success' &&
            response.data &&
            response.data.length > 0
          ) {
            return of(response.data[0]);
          } else {
            return of('Failed to generate content from backend');
          }
        }),
        catchError((error) => {
          throw error;
        })
      );
  }
}
